import React, { useState, useEffect } from 'react';
import './QuickPick.css';
import './FunPage.css';
import Cookies from 'js-cookie';

const FunPage = () => {
    const [media, setMedia] = useState({ movies: [], 'tv-shows': [] });
    const [visibleMedia, setVisibleMedia] = useState({ movies: [], 'tv-shows': [] });
    const [isLoading, setIsLoading] = useState(true);
    const username = Cookies.get("username") || 'default';

    const personalityTypes = [
        { type: "Analysts", types: [{ name: "Architect", mbti: "INTJ", icon: "fa-bank", description: "Imaginative and strategic thinkers, with a plan for everything." }, { name: "Logician", mbti: "INTP", icon: "fa-lightbulb-o", description: "Innovative inventors with an unquenchable thirst for knowledge." }, { name: "Commander", mbti: "ENTJ", icon: "fa-angle-double-up", description: "Bold, imaginative and strong-willed leaders, always finding a way – or making one." }, { name: "Debater", mbti: "ENTP", icon: "fa-gavel", description: "Smart and curious thinkers who cannot resist an intellectual challenge." }] },
        { type: "Diplomats", types: [{ name: "Advocate", mbti: "INFJ", icon: "fa-graduation-cap", description: "Quiet and mystical, yet very inspiring and tireless idealists." }, { name: "Mediator", mbti: "INFP", icon: "fa-heart", description: "Poetic, kind and altruistic people, always eager to help a good cause." }, { name: "Protagonist", mbti: "ENFJ", icon: "fa-star", description: "Charismatic and inspiring leaders, able to mesmerize their listeners." }, { name: "Campaigner", mbti: "ENFP", icon: "fa-smile-o", description: "Enthusiastic, creative and sociable free spirits, who can always find a reason to smile." }] },
        { type: "Sentinels", types: [{ name: "Logistician", mbti: "ISTJ", icon: "fa-hand-spock-o", description: "Practical and fact-minded individuals, whose reliability cannot be doubted." }, { name: "Defender", mbti: "ISFJ", icon: "fa-handshake-o", description: "Very dedicated and warm protectors, always ready to defend their loved ones." }, { name: "Executive", mbti: "ESTJ", icon: "fa-clipboard", description: "Excellent administrators, unsurpassed at managing things – or people." }, { name: "Consul", mbti: "ESFJ", icon: "fa-users", description: "Extraordinarily caring, social and popular people, always eager to help." }] },
        { type: "Explorers", types: [{ name: "Virtuoso", mbti: "ISTP", icon: "fa-wrench", description: "Bold and practical experimenters, masters of all kinds of tools." }, { name: "Adventurer", mbti: "ISFP", icon: "fa-paint-brush", description: "Flexible and charming artists, always ready to explore and experience something new." }, { name: "Entrepreneur", mbti: "ESTP", icon: "fa-rocket", description: "Smart, energetic and very perceptive people, who truly enjoy living on the edge." }, { name: "Entertainer", mbti: "ESFP", icon: "fa-music", description: "Spontaneous, energetic and enthusiastic people – life is never boring around them." }] }
    ];

    useEffect(() => {
        const storedMoviesList = localStorage.getItem('sortedWatchList-movies');
        const storedTVList = localStorage.getItem('sortedWatchList-tv-shows');

        const parsedMoviesList = storedMoviesList ? JSON.parse(storedMoviesList) : [];
        const parsedTVList = storedTVList ? JSON.parse(storedTVList) : [];

        setMedia({ movies: parsedMoviesList, 'tv-shows': parsedTVList });
        setVisibleMedia({ movies: parsedMoviesList, 'tv-shows': parsedTVList });

        setIsLoading(false);
    }, [username]);

    const renderQuickPicks = (type) => {
        const finalList = visibleMedia[type]; // Display items for quick picks
        return (
            <>
                <div style={{fontSize:"20px", color: "lightgray", marginLeft: "15px",paddingBottom:"10px", paddingTop:"20px" }}><a href={`/watchlist-game?m=${type}`} style={{ color: "white" }}>{type.toUpperCase()} WATCHLIST</a></div>
                <div className="grid-container-all">

                    {finalList.length > 0 ? (
                        finalList.map((item, index) => (
                            <div className="grid-item" key={index}>
                                <a target='_blank' rel='noreferrer' href={`${item.url}/watch`} target="_blank" rel="noopener noreferrer" style={{ fontSize: "16px", marginLeft: "15px" }}>
                                    <img style={{ width: "250px" }} src={item.full_poster_path} alt={item.title} />
                                </a>
                            </div>
                        ))
                    ) : (
                        <div className="quick-pick-card" onClick={() => handleQuickPickSelection(type)}>
                            <button className="btn btn-primary">QUICK PICK {type.replace('-', ' ')?.toUpperCase()}</button>
                        </div>
                    )}
                </div>


            </>
        );
    };

    const handleQuickPickSelection = (type) => {
        window.location.href = `/quick-pick?m=${type}`;
    };

    return (
        <>
            <div className="header-container" style={{ paddingTop: "20px" }}>
            </div>
            <div >
                <div className="" style={{}}>
                    {isLoading ? <p>Loading...</p> : (
                        <>
                            {renderQuickPicks('movies')}
                            {renderQuickPicks('tv-shows')}
                        </>
                    )}
                </div>
            </div>
            <br />

            <div className="" style={{ marginTop: "0px", marginLeft: "35px" }}>
                <h3><strong><a style={{ color: "lightgray" }} href="/persona-match">STARS ALIGNED</a></strong></h3>
                <span style={{ fontSize: "26px", color: "lightgray" }}>  Take a quick to get recommendations based on your  <a style={{ color: "lightgray", fontSize: "22px", textDecoration: "underline" }} href="/persona-match">PERSONALITY TYPE</a>.<br />Or, select one below.</span>
                <div className="personality-groups">
                    {personalityTypes.map((group) => (
                        <div key={group.type} className="personality-group">
                            <div className="personality-types-row">
                                {group.types.map((personality) => (
                                    <div key={personality.name} style={{ backgroundColor: "" }} className="personality-type-card">
                                        <center>
                                            <a href={`/persona-match?mbti=${personality.mbti}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                <span style={{ paddingTop: "5px", paddingLeft: "5px", color: "white", fontSize: "24px" }}><i className={`fa ${personality.icon}`} style={{ fontSize: "24px", marginBottom: "10px", paddingRight: "5px" }}></i> {personality.name?.toUpperCase()}</span>
                                                <img style={{ width: "250px", borderRadius: "10px" }} src={`/static/images/mbti/${personality.mbti}.png`} />
                                                <div style={{ color: "lightgray", fontSize: "22px", paddingTop: "5px" }}>{personality.description}</div>
                                            </a>
                                        </center>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default FunPage;
