import React, { useState, useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import './QuickPick.css';
import Cookies from 'js-cookie';
import 'font-awesome/css/font-awesome.min.css';

const WatchListGame = () => {
    const [watchList, setWatchList] = useState([]);
    const [library, setLibrary] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [undoAvailable, setUndoAvailable] = useState({});
    const username = Cookies.get("username") || 'default';
    const location = useLocation();
    const [mediaType, setMediaType] = useState(null);

    const handleQuickPickSelection = (type) => {
        window.location.href = `/quick-pick?m=${type}`;
    };

    const gotoPublicPage = (type) => {
        window.location.href = `/watchlist?w=${username}&m=${type}`;
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const mediaTypeParam = searchParams.get('m');
        if (mediaTypeParam === 'tv-shows' || mediaTypeParam === 'movies') {
            setMediaType(mediaTypeParam);
            const storedList = localStorage.getItem(`sortedWatchList-${mediaTypeParam}`);
            const parsedList = storedList ? JSON.parse(storedList) : [];
            setWatchList(parsedList);

            fetch(`/api/watchlist/${username}`)
                .then(response => response.json())
                .then(data => {
                    const list = data[mediaTypeParam]?.map(item => ({ ...item[1], id: item[0] })) || [];
                    const uniqueList = removeDuplicates(list, parsedList);
                    setLibrary(uniqueList);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching watchlist:', error);
                    setIsLoading(false);
                });
        }
    }, [location.search, username]);

    useEffect(() => {
        if (mediaType) {
            localStorage.setItem(`sortedWatchList-${mediaType}`, JSON.stringify(watchList));
        }
    }, [watchList, mediaType]);

    useEffect(() => {
        if (mediaType) {
            localStorage.setItem(`library-${mediaType}`, JSON.stringify(library));
        }
    }, [library, mediaType]);

    const removeDuplicates = (list1, list2) => {
        const seen = new Set(list2.map(item => item.tmdb_id));
        return list1.filter(item => !seen.has(item.tmdb_id));
    };

    const handleWatchSoon = (index, fromLibrary) => {
        if (fromLibrary) {
            const item = library[index];
            setWatchList([...watchList, item]);
            setLibrary(library.filter((_, i) => i !== index));
        } else {
            const item = watchList[index];
            setWatchList([...watchList.filter((_, i) => i !== index), item]);
        }
    };
    const moveToPosition = (currentIndex, targetPosition) => {
        setWatchList((prevWatchList) => {
            // Ensure targetPosition is within valid range
            if (targetPosition < 1 || targetPosition > prevWatchList.length) {
                return prevWatchList;
            }

            // Extract the item to move
            const item = prevWatchList[currentIndex];

            // Remove the item from its current position
            const filteredList = prevWatchList.filter((_, i) => i !== currentIndex);

            // Insert the item at the target position
            const newWatchList = [
                ...filteredList.slice(0, targetPosition - 1),
                item,
                ...filteredList.slice(targetPosition - 1),
            ];

            // Save the updated watchlist to localStorage
            localStorage.setItem(`sortedWatchList-${mediaType}`, JSON.stringify(newWatchList));

            return newWatchList;
        });
    };
    const handleWatchLater = (index, fromLibrary) => {
        if (fromLibrary) {
            const item = library[index];
            setLibrary([item, ...library.filter((_, i) => i !== index)]);
        } else {
            const item = watchList[index];
            setWatchList(watchList.filter((_, i) => i !== index));
            setLibrary([item, ...library]);
        }
    };
    const moveToStart = (index) => {
        setWatchList((prevWatchList) => {
            const item = prevWatchList[index];
            const newWatchList = [item, ...prevWatchList.filter((_, i) => i !== index)];
            localStorage.setItem(`sortedWatchList-${mediaType}`, JSON.stringify(newWatchList));
            return newWatchList;
        });
    };

    const moveToEnd = (index) => {
        setWatchList((prevWatchList) => {
            const item = prevWatchList[index];
            const newWatchList = [...prevWatchList.filter((_, i) => i !== index), item];
            localStorage.setItem(`sortedWatchList-${mediaType}`, JSON.stringify(newWatchList));
            return newWatchList;
        });
    };
    const moveItem = (index, direction) => {
        setWatchList((prevWatchList) => {
            // Create a copy of the watchlist
            const newWatchList = [...prevWatchList];

            // Swap the current item with the target position
            const targetIndex = index + direction;
            [newWatchList[index], newWatchList[targetIndex]] = [newWatchList[targetIndex], newWatchList[index]];

            // Save updated watchlist to localStorage
            localStorage.setItem(`sortedWatchList-${mediaType}`, JSON.stringify(newWatchList));

            return newWatchList;
        });
    };
    const handleDeleteItem = (index, fromLibrary, mediaId) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            const handleDelete = (mediaId) => {
                fetch(`/w/${username}/delete/${mediaId}`, { method: 'GET' })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            setUndoAvailable(prev => ({ ...prev, [mediaId]: true }));
                        }
                    })
                    .catch(error => console.error('Error deleting item:', error));
            };
            if (fromLibrary) {
                const itemToDelete = library[index];
                setLibrary(library.filter((_, i) => i !== index));
                handleDelete(itemToDelete.id);
            } else {
                const itemToDelete = watchList[index];
                setWatchList(watchList.filter((_, i) => i !== index));
                handleDelete(itemToDelete.id);
            }
        }
    };

    return (
        <div className="watchlist-game-container" style={{}}>

            {isLoading ? (
                <p>Loading watchlist...</p>
            ) : (
                <>
                    <p style={{ color: "lightgray", paddingTop: "10px" }}></p>
                    <div className="quick-pick-card" onClick={() => handleQuickPickSelection(mediaType)}>
                        <button className="btn btn-primary">HELP ME PICK {mediaType.replace('-', ' ')?.toUpperCase()}</button>
                    </div>

                    <div style={{ display: 'flex', marginTop: '20px' }}>
                        <div style={{ fontSize: "20px", color: "lightgray", marginLeft: "5px" }}>WATCH NOW</div>
                    </div>
                    <div className="grid-container-all">
                        {watchList.length > 0 ? (
                            watchList.map((item, index) => (
                                <div className="grid-item" key={index} style={{ position: "relative" }}>
                                    {/* Display rank for top 3 items */}
                                    {index < 3 && (
                                        <div className="top-rank">
                                            {index + 1}
                                        </div>
                                    )}

                                    {/* Buttons for reordering */}
                                    <div className="overlay-buttons">
                                        {[1, 2, 3].map((position) => (
                                            <button
                                                key={position}
                                                disabled={index === position - 1}
                                                onClick={() => moveToPosition(index, position)}
                                                className={`num-btn btn-overlay ${index === position - 1 ? '' : ''}`}
                                            >
                                                {position}
                                            </button>
                                        ))}
                                    </div>

                                    {/* Item Image and Details */}
                                    <a
                                        href={`/details/${item.tmdb_id}/${encodeURIComponent(item.title)}/${new Date(
                                            item.release_date
                                        ).getFullYear()}/true?media_type=${mediaType}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img style={{ width: "260px" }} src={item.full_poster_path} alt={item.title} />
                                    </a>

                                    <div className="hover-overlay watch-later-overlay" onClick={() => handleWatchLater(index, false)}>
                                        <span style={{ fontSize: "20px" }}>WATCH LATER</span>
                                        <i style={{ position: "absolute", right: "0px", fontSize: "24px" }} className="fa fa-step-forward"></i>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p style={{ color: "lightgray" }}>Your watch now list is empty.</p>
                        )}
                    </div>



                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <div style={{ fontSize: "20px", color: "lightgray", marginLeft: "15px" }}>WATCH LATER <i style={{ fontSize: "20px" }} className="fa fa-step-forward"></i></div>
                    </div>
                    <div className="grid-container-all">
                        {library.length > 0 ? (
                            library.map((item, index) => (
                                <div className="grid-item" key={index}>
                                    <div className="hover-overlay watch-soon-overlay" onClick={() => handleWatchSoon(index, true)}>
                                        <i style={{ position: "absolute", left: "6px", fontSize: "24px" }} className="fa fa-plus"></i>
                                        <span style={{ fontSize: "10px" }}>WATCHLIST</span>
                                    </div>
                                    <span className="delete-later-btn" onClick={() => handleDeleteItem(index, true, item.tmdb_id)}>
                                        <i style={{ color: "red", position: "absolute", top: "0px", left: "0px", fontSize: "24px" }} className="fa fa-times"></i>
                                    </span>
                                    <a href={`/details/${item.tmdb_id}/${encodeURIComponent(item.title)}/${new Date(item.release_date).getFullYear()}/true?media_type=${mediaType}`} target="_blank" rel="noreferrer">
                                        <img style={{ width: "150px" }} src={item.full_poster_path} alt={item.title} />
                                    </a>
                                </div>
                            ))
                        ) : (
                            <p style={{ color: "lightgray" }}>Your watch later list is empty.</p>
                        )}

                    </div>
                    <p style={{ color: "lightgray", paddingTop: "20px" }}>Want to share your watchlist with friends?
                    </p>
                    <div className="quick-pick-card" onClick={() => gotoPublicPage(mediaType)}>
                        <button className="btn btn-primary">PUBLIC PAGE </button>
                    </div>

                </>
            )}
        </div>
    );
};

export default WatchListGame;
