import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import CastDetails from './CastDetails';
import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Ensure your CSS is correctly imported
import 'font-awesome/css/font-awesome.min.css';
import Watchlist from './Watchlist';
import ProfilePage from './ProfilePage';
import DetailsPage from './DetailsPage';
import HomePage from './HomePage';
import QuickPick from './QuickPick';
import FunPage from './FunPage';
import { ToastProvider } from './ToastContext';
import Toast from './Toast';
import PersonaMatch from './PersonaMatch';
import WatchListGame from './WatchListGame';  // Import the new component

function App() {
  // Generate a random hash for the username
  const randomHash = Math.random().toString(36).substring(2, 15) 
                      + Math.random().toString(36).substring(2, 15);
  const [currentUsername, setCurrentUsername] = useState(() => Cookies.get('username') || null);
  const [isWatchlistAnimated, setIsWatchlistAnimated] = useState(false);
  const [watchlistShortName, setWatchlistShortName] = useState(() => Cookies.get('watchlistShortName') || 'WATCHLIST');
  const navLinkColor = "#61dafb";

  // Function to trigger the headshake animation
  const triggerWatchlistAnimation = useCallback(() => {
    setIsWatchlistAnimated(true);
    setTimeout(() => setIsWatchlistAnimated(false), 1000); // Animation lasts for 1s
  }, []);

  useEffect(() => {
    // Fetch the username from the cookie
    let username = Cookies.get('username') || null;
    if (username === null) {
      // Set the username to the random hash
      Cookies.set('username', randomHash, { expires: 30 });
      username = randomHash;
    }
    setCurrentUsername(username);
  }, []);

  useEffect(() => {
    window.triggerWatchlistAnimation = triggerWatchlistAnimation;
  }, [triggerWatchlistAnimation]);

  useEffect(() => {
    const handleRouteChange = () => {
      const newUsername = new URLSearchParams(window.location.search).get('w');
      if (newUsername && newUsername.toLowerCase() !== currentUsername.toLowerCase()) {
        Cookies.set('username', newUsername, { expires: 30 });
        setCurrentUsername(newUsername);
      }
    };
    window.addEventListener('popstate', handleRouteChange);
    return () => window.removeEventListener('popstate', handleRouteChange);
  }, [currentUsername]);

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      window.location.href = `/search-page?s=${event.target.value}`;
    }
  };

  return (
    <ToastProvider>
      <Router>
        <Toast />
        <div className="fixed-top-nav ">
          <div className="container mobile-show" style={{ width: "100%", backgroundColor: "#000" }}>
            <nav className="navbar navbar-dark" style={{ backgroundColor: "#000" }}>
              <div className="nav-left " style={{ fontSize: "36px" }}>                 
                
                <NavLink to={`/search-page`} className={`nav-link`} style={({ isActive }) => ({
                  color: isActive ? 'white' : navLinkColor,
                })}>
                  <i className="fa fa-search"></i> <span id="watchlist-nav" className='mobile-hide'>&nbsp; SEARCH</span>
                </NavLink>
              </div>
              <div className="nav-right" style={{ fontSize: "36px" }}>
                <NavLink to="/profile" className="nav-link" style={({ isActive }) => ({
                  color: isActive ? 'white' : navLinkColor,
                })}>
                  <i className="fa fa-th-list"></i><span className='mobile-hide'>&nbsp; LISTS</span>
                </NavLink>
              </div>
            </nav>
          </div>
          <div className="container mobile-hide" style={{ width: "100%", backgroundColor: "#000" }}>
            <nav className="navbar navbar-dark" style={{ backgroundColor: "#000" }}>
              <div className="nav-left " style={{ fontSize: "20px" }}>
                <NavLink to="/search-page" className={`nav-link ${isWatchlistAnimated ? 'animate__animated animate__tada' : ''}`} style={({ isActive }) => ({
                  color: isActive ? 'white' : navLinkColor,
                })}>
                  <i className="fa fa-list-ol"></i> <span className='mobile-hide'>&nbsp; WATCH</span>
                </NavLink>
                <input
                  type="text"
                  className="search-input "
                  placeholder="Search"
                  onKeyPress={handleSearchKeyPress}
                  style={{ width: '200px', height: '30px', marginLeft: '20px', marginRight: '20px' }}
                />
                <NavLink to={`/watchlist-game?m=movies`} className={`nav-link`} style={({ isActive }) => ({
                  color: isActive ? 'white' : navLinkColor,
                })}>
                  <i className="fa fa-film"></i> <span id="watchlist-nav" className='mobile-hide'>&nbsp; MOVIES</span>
                </NavLink>                
                <NavLink to={`/watchlist-game?m=tv-shows`} className={`nav-link`} style={({ isActive }) => ({
                  color: isActive ? 'white' : navLinkColor,
                })}>
                  <i className="fa fa-tv"></i> <span id="watchlist-nav" className='mobile-hide'>&nbsp; TV</span>
                </NavLink>                
              </div>
              <div className="nav-right" style={{ fontSize: "20px" }}>
                <NavLink to="/profile" className="nav-link" style={({ isActive }) => ({
                  color: isActive ? 'white' : navLinkColor,
                })}>
                  <i className="fa fa-th-list"></i><span className='mobile-hide'>&nbsp; LISTS</span>
                </NavLink>
              </div>
            </nav>
          </div>
        </div>
        <br /><br />
        <div className="container body-content" style={{ backgroundColor: "#191C24", borderRadius: "10px" }}>
          <Routes>
            <Route path="/details/:tmdb_id/:title/:release_year/:showAddButton" element={<DetailsPage />} />
            <Route path="/cast-details/:credit_id/:media_type/:title" element={<CastDetails />} />
            <Route path="/" element={<FunPage />} />
            <Route path="/search-page" element={<HomePage />} />
            <Route path="/details/:media_type/:id" element={<DetailsPage />} />
            <Route path="/watchlist" element={<Watchlist username={currentUsername} />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/persona-match" element={<PersonaMatch />} />
            <Route path="/quick-pick" element={<QuickPick />} />
            <Route path="/watchlist-game" element={<WatchListGame />} /> {/* Add the new route */}
          </Routes>
          <br /><br /><br /><br /><br /><br />
          <div className="footer-container" style={{ marginTop: "30px" }}>
            <a href="/" className="navbar-brand">
              <img src="/static/images/watch_it_buddy_logo.png" alt="Watch It Buddy Logo" width="200" />
            </a>
          </div>
          <div className="footer-container" style={{ marginBottom: "30px" }}>
            Powered by &nbsp;&nbsp;
            <a href="https://www.justwatch.com/"><img src="/static/images/justwatch_logo.png" alt="JustWatch" width="100" /></a>&nbsp;&nbsp;and&nbsp;&nbsp;
            <a href="https://www.themoviedb.org/"><img src="/static/images/tmdb_logo.png" alt="JustWatch" width="100" /></a>
            <p />
          </div>
          <br />
        </div>
      </Router>
    </ToastProvider>
  );
}

export default App;
