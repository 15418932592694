import React, { useState, useEffect } from 'react';
import './SearchBar.css';
import './SearchResults.css';
import SearchResults from './SearchResults';
import quotesData from './quotes.json';

const SearchBar = ({ onRecommendationsFetched, initialSearchTerm }) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm || '');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    // Get a random quote
    const randomQuote = quotesData[Math.floor(Math.random() * quotesData.length)];
    // Format the quote
    const formattedQuote = randomQuote ? `“${randomQuote.q}” - ${randomQuote.t}` : '';
    // Set the formatted quote as the placeholder for the search input
    document.getElementById("search-textfield").placeholder = formattedQuote.toLocaleUpperCase();
  }, []); // This effect runs only once on component mount

  useEffect(() => {
    if (initialSearchTerm) {
      setSearchTerm(initialSearchTerm);
      executeSearch(initialSearchTerm);
    }
  }, [initialSearchTerm]);

  const updateUI = (message, spinnerVisibility = 'none') => {
    document.getElementById("searching-txt").innerText = message;
    document.getElementById("searching-spinner").style.display = spinnerVisibility;
  }

  const fetchAPI = (endpoint) => {
    updateUI('Fetching...', 'inline-block');
    fetch(endpoint)
      .then(response => response.json())
      .then(data => {
        setSearchResults(data.searchResults.results);
        updateUI(data.searchResults.results.length > 0 ? `Results for '${initialSearchTerm}'` : `No results found for '${searchTerm}'`);
      })
      .catch(error => {
        console.error('Error:', error);
        setSearchResults([]);
        updateUI('Failed to fetch data.');
      });
  };

  const executeSearch = (term = searchTerm) => {
    if (!term) return;
    const endpoint = `/search?title=${encodeURIComponent(term)}`;
    initialSearchTerm = term;
    setSearchResults([]);
    fetchAPI(endpoint);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      executeSearch();
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const clearSearchResults = () => {
    updateUI('');
    setSearchResults([]);
    setSearchTerm(''); // Clear search term
    document.getElementById("search-textfield").placeholder = 'Search for TV shows and movies'; // Reset placeholder when clearing results
  }

  return (
    <> 
      <div className="search-bar-container">
        <div className="instructions">         
          <p style={{paddingTop: "5px", fontSize:"16px"}}></p> 
        </div>
        <div className="search-bar">
          <input
            id="search-textfield"
            type="text" 
            className="form-control"
            placeholder="Search for TV shows and movies"
            value={searchTerm}
            style={{borderColor:"gray", color:"gray", backgroundColor:"black", fontSize: ".9em", position:"relative", width:"100%"}}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}          
          />
          <button onClick={() => executeSearch()} className="btn btn-primary search-button">
            SEARCH
          </button>
          <span onClick={clearSearchResults} className="search-clear">
            CLEAR
          </span>
        </div>
      </div> 
      <span style={{position:"relative", marginLeft:"15px"}} id="searching-txt"></span>
      <i className="fas fa-refresh fa-spin" id="searching-spinner"></i>
      <SearchResults results={searchResults} onRecommendationsFetched={onRecommendationsFetched} fetchRecommendations={fetchAPI} />
    </>
  );
};

export default SearchBar;
